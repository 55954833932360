import React from 'react'
import LondonTemplate from '../../../templates/LondonTemplate'

const LondonSchedule = () => {
    return (
        <LondonTemplate>
            <div className="salesforce-footer"></div>
            <div className="heroContent">
                <div className="heroSky">
                    <div className="hottieCenter">
                    <img className="herohottie" src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/hero/2023_BC_Regional-Landing-Page_Hootie.gif" alt="background" />
                    </div>
                    <img className="heroballon" src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/hero/2023_BC_Regional-Landing-Page_UK-Balloon.gif" alt="background" />
                </div>
            </div>

            <section className="container-content">
                <h1 className="h1Internal">Schedule</h1>
                <h3>Plan your adventure.</h3>

                <div className="panel-group" id="faq-accordion" role="tablist" aria-multiselectable="true" style={{maxWidth: '1280px'}}>
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="faq-1">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#faq-accordion" href="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                                    <span className="glyphicon glyphicon-plus-sign plus-minus "></span><span className="glyphicon glyphicon-minus-sign plus-minus"></span>
                                    <div className="panel-left">Pre-Event Certification</div>
                                    <div className="panel-right">8-May-2023</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="faq-1">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <div className="panel-left">Event Check-In/Badge Pickup</div>
                                    <div className="panel-right">16:00-20:30</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Certification Information Desk</div>
                                    <div className="panel-right">16:00-20:30</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Certification Exams (optional)</div>
                                    <div className="panel-right">18:00</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="faq-2">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#faq-accordion" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                    <span className="glyphicon glyphicon-plus-sign plus-minus "></span><span className="glyphicon glyphicon-minus-sign plus-minus"></span>
                                    <div className="panel-left">Bootcamp Day 1</div>
                                    <div className="panel-right">9-May-2023</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="faq-2">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <div className="panel-left">Event Check-In/Badge Pickup</div>
                                    <div className="panel-right">08:00-12:00</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Certification Information Desk</div>
                                    <div className="panel-right">8:00-18:00</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Tracks in Session</div>
                                    <div className="panel-right">09:00-12:00</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Lunch</div>
                                    <div className="panel-right">12:00-13:30</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Tracks in Session</div>
                                    <div className="panel-right">13:30-18:00</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Trailblazer Welcome Reception</div>
                                    <div className="panel-right">20:00-22:00</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="faq-3">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#faq-accordion" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                    <span className="glyphicon glyphicon-plus-sign plus-minus "></span><span className="glyphicon glyphicon-minus-sign plus-minus"></span>
                                    <div className="panel-left">Bootcamp Day 2</div>
                                    <div className="panel-right">10-May-2023</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="faq-3">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <div className="panel-left">Certification Information Desk</div>
                                    <div className="panel-right">08:00-18:30</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Tracks in Session</div>
                                    <div className="panel-right">09:00-12:00</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Lunch</div>
                                    <div className="panel-right">12:00-13:30</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Tracks in Session</div>
                                    <div className="panel-right">13:30-18:00</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Study Hall</div>
                                    <div className="panel-right">18:00-19:00</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="faq-5">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#faq-accordion" href="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                                    <span className="glyphicon glyphicon-plus-sign plus-minus "></span><span className="glyphicon glyphicon-minus-sign plus-minus"></span>
                                    <div className="panel-left">Bootcamp Day 3</div>
                                    <div className="panel-right">11-May-2023</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-5" className="panel-collapse collapse" role="tabpanel" aria-labelledby="faq-5">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <div className="panel-left">Certification Information Desk</div>
                                    <div className="panel-right">08:00-17:30</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Tracks in Session</div>
                                    <div className="panel-right">09:00-12:00</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Lunch</div>
                                    <div className="panel-right">12:00-13:30</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Tracks in Session</div>
                                    <div className="panel-right">13:30-17:00</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Study Hall</div>
                                    <div className="panel-right">17:00-18:00</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="faq-4">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#faq-accordion" href="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                                    <span className="glyphicon glyphicon-plus-sign plus-minus "></span><span className="glyphicon glyphicon-minus-sign plus-minus"></span>
                                    <div className="panel-left">Bootcamp Day 4</div>
                                    <div className="panel-right">12-May-2023</div>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse-4" className="panel-collapse collapse" role="tabpanel" aria-labelledby="faq-4">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <div className="panel-left">Certification Information Desk</div>
                                    <div className="panel-right">08:00-16:30</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Tracks in Session</div>
                                    <div className="panel-right">09:00-12:00</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Lunch</div>
                                    <div className="panel-right">12:00-13:30</div>
                                </div>
                                <div className="panel-row">
                                    <div className="panel-left">Certification Exams (optional)</div>
                                    <div className="panel-right">14:00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-content">
                <div className="center">
                    <img className="logo logoInternal" src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/home/2023-Bootcamp-Seal_London_Color.png" />
                </div>
            </section>

            <div className="characters schedule-bg">
                <img src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/schedule/Schedule.png" />
            </div>
        </LondonTemplate>
    )
}

export default LondonSchedule
